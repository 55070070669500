// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from "react";
import { render } from "react-dom";
import "./app.global.css";
import Root from "./components/Root";

document.addEventListener("DOMContentLoaded", () =>
  render(<Root />, document.getElementById("root"))
);
