import React, { ReactNode, useReducer } from "react";

import Amplify from "aws-amplify";
import { I18n } from "@aws-amplify/core";
import awsconfig from "../aws-exports";
import { dict } from "../components/amplify/AmplifyI18n";

Amplify.configure(awsconfig);

I18n.putVocabularies(dict);

export interface StateType {
  authState: any | null;
  cognitoUser: any | null;
  userName: any | null;
  encryptedMeetingId: any | null;
  group: any | null;
}

export interface Action {
  type: string;
}

export interface SetAuthStatusActon extends Action {
  payload: {
    authState: any | null;
    cognitoUser: any | null;
    userName: any | null;
    encryptedMeetingId: any | null;
    group: any | null;
  };
}

const initialState: StateType = {
  authState: null,
  cognitoUser: null,
  userName: null,
  encryptedMeetingId: null,
  group: null,
};

interface AuthStateValue {
  state: StateType;
  registAuth: (user: string, encryptedMeetingId: string, grounp?: {}) => void;
  registAuthWithPayload: (payload: {}) => void;
}

const context = React.createContext<AuthStateValue>({
  state: initialState,
  registAuth: (): void => {},
  registAuthWithPayload: (): void => {},
});

export const getAuthStatusContext = () => {
  return context;
};

const reducer = (state: any, action: any): any => {
  switch (action.type) {
    case "SET_AUTHENTICATION":
      return {
        authState: action.payload.authState,
        cognitoUser: action.payload.cognitoUser,
        userName: action.payload.userName,
        encryptedMeetingId: action.payload.encryptedMeetingId || "",
        group: action.payload.group,
      };
    default:
      throw new Error();
  }
};

type Props = {
  children: ReactNode;
};

export default function AuthStateProvider(props: Props) {
  const { children } = props;
  const AuthStateContext = getAuthStatusContext();
  const [state, dispatch] = useReducer(reducer, initialState);
  const registAuth = (
    user: string,
    encryptedMeetingId: string,
    group: {} | null = null
  ) => {
    dispatch({
      type: "SET_AUTHENTICATION",
      payload: {
        userName: user,
        encryptedMeetingId: encryptedMeetingId,
        authState: null,
        cognitoUser: null,
        group: group,
      },
    });
    console.log("dispatch");
  };

  const registAuthWithPayload = (payload: any) => {
    dispatch({
      type: "SET_AUTHENTICATION",
      payload,
    });
  };

  return (
    <AuthStateContext.Provider
      value={{ state, registAuth, registAuthWithPayload }}
    >
      {children}
    </AuthStateContext.Provider>
  );
}
