// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { ReactNode, useState } from "react";

import getUIStateContext, { initialState } from "../context/getUIStateContext";

type Props = {
  children: ReactNode;
};

export default function UIStateProvider(props: Props) {
  const { children } = props;
  const UIStateContext = getUIStateContext();
  const [isAdmin] = useState<boolean>(initialState);

  return (
    <UIStateContext.Provider
      value={{
        isAdmin,
      }}
    >
      {children}
    </UIStateContext.Provider>
  );
}
