// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

enum MessageTopic {
  Capture = "capture",
  ToggleVideoResolution = "toggle-video-resolution",
  PleaseCapture = "please-capture",
  ReturnCapture = "return-capture",
  SendCoodinate = "send-coodinate",
}

export default MessageTopic;
