// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from "react";
import { HashRouter } from "react-router-dom";

import ChimeProvider from "../providers/ChimeProvider";
import UIStateProvider from "../providers/UIStateProvider";
import AuthStateProvider from "../providers/AuthStateProvider";

import Routes from "../Routes";

const Root = () => (
  <HashRouter>
    <ChimeProvider>
      <UIStateProvider>
        <AuthStateProvider>
          <Routes />
        </AuthStateProvider>
      </UIStateProvider>
    </ChimeProvider>
  </HashRouter>
);

export default Root;
