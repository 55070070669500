// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

export default function useDeviceResolution(): any {
  return {
    // low: {
    //   label: '低画質',
    //   divRate: 3,
    //   frameRate: 5,
    //   maxBandwidthKbps: 300,
    // },
    // middle: {
    //   label: '中画質',
    //   videoWidth: 360,
    //   videoHeight: 180,
    //   frameRate: 5,
    //   maxBandwidthKbps: 250,
    // },
    // high: {
    //   label: '高画質',
    //   videoWidth: 640,
    //   videoHeight: 320,
    //   frameRate: 10,
    //   maxBandwidthKbps: 500,
    // },
    middle: {
      label: "中画質",
      videoWidth: 640,
      videoHeight: 320,
      frameRate: 10,
      maxBandwidthKbps: 500,
    },
    high: {
      label: "高画質",
      videoWidth: 1280,
      videoHeight: 640,
      frameRate: 15,
      maxBandwidthKbps: 1000,
    },
  };
}
